body {
  --board-renderer-border: 1px solid rgba(0, 0, 0, 0.1);
  --board-renderer-max-width: 120px;
  --board-renderer-name-background-color: var(--secondary-text-color);
  --board-renderer-name-font: normal 13px var(--theme-font);
  --board-renderer-icon-size: 24px;
  --board-renderer-icon-border-radius: 10px;
  --board-renderer-font: bold 16px/25px var(--md-icon-font, 'Material Symbols Outlined');
  --board-renderer-icon-edit-background-color: #95d40f;
  --board-renderer-icon-view-background-color: #27a1de;

  /* list page style*/
  --board-list-background-color: var(--md-sys-color-background);
  --board-list-border-radius: var(--border-radius);
  --board-list-box-border: var(--margin-default) solid #fff;
  --board-list-box-shadow: var(--box-shadow);
  --board-list-margin: var(--margin-default);
  --board-list-tile-background-color: #fff;
  --board-list-tile-name-font: bold 14px/18px var(--theme-font);
  --board-list-tile-name-color: var(--md-sys-color-secondary);
  --board-list-tile-description-font: 12px/14px var(--theme-font);
  --board-list-tile-description-color: var(--secondary-text-color);
  --board-list-tile-icon-color: rgba(0, 0, 0, 0.4);
  --board-list-star-color: rgba(0, 0, 0, 0.4);
  --board-list-star-active-color: var(--status-warning-color);

  --card-list-rows-height: 180px;
  --card-list-color: var(--md-sys-color-on-surface);
  --card-list-background-color: var(--md-sys-color-surface-container-lowest);
  --card-list-border-radius: var(--border-radius);
  --card-list-flip-transform: rotateX(180deg);
  --card-list-create-border: 1px dashed var(--md-sys-color-primary);
  --card-list-create-border-radius: var(--border-radius);
  --card-list-create-icon-color: var(--md-sys-color-primary);
  --card-list-create-color: var(--md-sys-color-secondary);
  --card-list-create-form-padding: 15px;
  --card-list-create-label-font: normal 14px var(--theme-font);
  --card-list-create-label-color: var(--md-sys-color-secondary);
  --card-list-create-input-font: normal 14px var(--theme-font);
  --card-list-create-input-color: var();
  --card-list-create-input-border: 1px solid rgba(0, 0, 0, 0.2);
  --card-list-create-input-border-radius: var(--border-radius);
  --card-list-create-input-padding: 2px 9px;
  --card-list-create-margin: 0 0 7px 0;

  /* board modeler page style */
  --edit-toolbar-background-color: var(--md-sys-color-secondary);
  --edit-toolbar-bigger-icon-size: 45px;
  --edit-toolbar-bigger-icon-line: 1px solid rgba(0, 0, 0, 0.1);
  --component-toolbar-background-color: var(--md-sys-color-background);
  --component-toolbar-icon-size: 45px;
  --component-toolbar-border: 1px solid rgba(0, 0, 0, 0.1);
  --component-menu-background-color: var(--md-sys-color-secondary-container);
  --component-menu-border-color: var(--md-sys-color-secondary);
  --component-menu-title: bold 11px/16px var(--theme-font);
  --component-menu-item-color: var(--md-sys-color-secondary);
  --component-menu-item-hover-color: var(--md-sys-color-secondary);
  --component-menu-item-icon-size: 20px;
  --property-sidebar-background-color: var(--md-sys-color-secondary-container);
  --property-sidebar-tab-icon-color: var(--md-sys-color-secondary);
  --property-sidebar-fieldset-border: 1px solid rgba(0, 0, 0, 0.2);
  --property-sidebar-fieldset-label-color: var(--md-sys-color-secondary);
  --property-sidebar-fieldset-label: normal 12px/16px var(--theme-font);
  --property-sidebar-fieldset-legend-color: var(--md-sys-color-on-secondary-container);
  --property-sidebar-fieldset-legend: bold 13px var(--theme-font);
  --scene-inspector-color: var(--md-sys-color-secondary);
  --scene-inspector-selected-background-color: rgba(115, 188, 28, 0.2);
  --scene-inspector-selected-border: 1px solid rgba(115, 188, 28, 1);
  --scene-inspector-name-background-color: var(--md-sys-color-primary);
  --scene-inspector-eye-icon-color: var(--md-sys-color-on-secondary-container);
}

@media (min-width: 461px) and (max-width: 1024px) {
  body {
    --card-list-create-form-padding: 7px;
  }
}
