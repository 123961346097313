body {
  --setting-background: var(--md-sys-color-background);

  --setting-let-background: var(--md-sys-color-on-primary);
  --setting-let-margin: var(--margin-wide);
  --setting-let-border-radius: var(--border-radius);
  --setting-let-border: var(--border-dim-color);

  --setting-title-font: var(--subtitle-font);
  --setting-title-color: var(--subtitle-text-color);

  --setting-content-padding: var(--padding-wide);
  --setting-content-font: normal 0.9em var(--theme-font);
  --setting-content-color: var(--md-sys-color-secondary);

  --setting-info-content-padding: 10px 15px;
  --setting-info-background-color: #f0f0f0;
  --setting-info-font: normal 14px/14px var(--theme-font);
  --setting-info-color: #666;
  --setting-info-appname-color: var(--md-sys-color-primary);

  --setting-icon-height: 170px;
  --setting-icon-margin: 40px 0 5px 0;
}
@media screen and (max-width: 460px) {
  body {
    --setting-let-margin: var(--margin-default);
    --setting-content-padding: var(--padding-default);
    --setting-icon-height: 140px;
    --setting-icon-margin: var(--margin-wide) 0;
  }
}
