body {
  --form-border: none;
  --form-margin: var(--spacing-medium);
  --form-max-width: 700px;
  --form-multi-column-max-width: 100%;
  --form-sublabel-font: normal 13px var(--theme-font);
  --form-grid-gap: 12px 5px;

  --legend-padding: var(--spacing-medium) 0;
  --legend-font: bold 16px var(--theme-font);
  --legend-text-color: var(--md-sys-color-on-secondary);
  --legend-border-bottom: 1px solid var(--md-sys-color-primary);

  --label-padding: 3px 0;
  --label-font: normal 14px var(--theme-font);

  --input-field-border: 1px solid rgba(0, 0, 0, 0.2);
  --input-field-border-radius: var(--border-radius);
  --input-field-padding: 2px 9px;
  --input-field-font: normal 14px var(--theme-font);

  --search-panel-background-color: rgba(0, 0, 0, 0.1);
  --search-panel-search-iconbutton-size: var(--icon-default-size);
  --search-form-box-shadow: 0 2px 3px 1px rgba(0, 0, 0, 0.15);
  --search-form-box-padding: 15px 30px 15px 15px;

  --ox-input-border: none;

  --file-uploader-border: 1px solid rgba(0, 0, 0, 0.1);
  --file-uploader-background-color: var(--md-sys-color-background);
  --file-uploader-font: normal 12px/20px var(--theme-font);
  --file-uploader-color: var(--md-sys-color-secondary);
  --file-uploader-icon-color: var(--md-sys-color-primary);
  --file-uploader-candrop-background-color: #fffde9;
  --file-uploader-label-padding: 3px 20px;
  --file-uploader-label-border-radius: var(--border-radius);
  --file-uploader-label-background-color: var(--md-sys-color-secondary);
  --file-uploader-label-font: normal 12px var(--theme-font);
  --file-uploader-label-color: #fff;
  --file-uploader-li-padding: 2px 5px 0px 5px;
  --file-uploader-li-border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
  --file-uploader-li-icon-margin: 2px 0 2px 5px;
  --file-uploader-li-icon-font: normal 15px var(--md-icon-font, 'Material Symbols Outlined');
  --file-uploader-li-icon-focus-color: var(--md-sys-color-primary);
}

@media screen and (max-width: 480px) {
  body {
    --label-font: normal 15px var(--theme-font);
  }
}

@media (min-width: 481px) and (max-width: 1024px) {
  body {
    --form-margin: 15px 0;
    --form-multi-column-max-width: 100%;
    --form-container-padding: 0 9px 12px 9px;
    --label-font: normal 14px var(--theme-font);
    --input-field-font: normal 15px var(--theme-font);
    --input-field-padding: 3px 5px;
  }
}

@media only screen and (max-width: 925px) {
  body {
    --form-margin: 14px 0;
    --form-multi-column-max-width: 100%;
    --form-container-padding: 0 9px 12px 9px;
    --input-field-padding: 3px 5px;
  }
}
