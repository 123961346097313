body {
  --context-toolbar-background-color: var(--md-sys-color-background);
  --context-toolbar-button-background-color: #fff;
  --context-toolbar-border: 1px solid rgba(57, 78, 100, 0.5);
  --context-toolbar-border-hover: 1px solid var(--md-sys-color-primary);
  --context-toolbar-button-max-width: auto;
  --context-toolbar-button-height: 36px;
  --context-toolbar-button-margin: var(--margin-narrow) 0 var(--margin-wide) 0;
  --context-toolbar-button-padding: 0 9px;
  --context-toolbar-button: normal 13px var(--theme-font);
  --context-toolbar-button-color: var(--md-sys-color-secondary);
  --context-toolbar-iconbutton-color: var(--primary-text-color);
  --context-toolbar-iconbutton-size: 16px;
  --context-toolbar-function-button-height: 34px;
  --context-toolbar-function-button-radius: 5px;
  --context-toolbar-function-button-background-color: #446281;
  --context-toolbar-function-button-hover-background-color: var(--md-sys-color-primary);
  --context-toolbar-function-iconbutton-size: 28px;
  --context-toolbar-function-iconbutton-color: var(--primary-background-color);
  --context-toolbar-function-button-lineheight: 36px;
  --context-toolbar-function-button-color: #fff;
  --context-toolbar-function-button-padding: 0 10px;
  --context-toolbar-function-button-border: 1px solid rgba(0, 0, 0, 0.1);
  --context-toolbar-list-background-color: var(--primary-background-color);
  --context-toolbar-list-text: bold 18px var(--theme-font);
  --context-toolbar-list-icon-size: var(--icon-default-size);
  --context-toolbar-side-padding: 15px;
  --context-toolbar-shadow-line: none;
  --context-toolbar-title: bold 19px var(--theme-font);

  --context-ui-background-color: rgba(40, 54, 68, 0.9);
  --context-ui-box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.2);
  --context-ui-border-radius: 10px;
  --context-ui-padding: 10px;
  --context-ui-list-color: #d0fdee;
  --context-ui-list-border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  --context-ui-list-border-hover-bottom: 1px solid var(--md-sys-color-primary);
  --context-ui-list-padding: 5px;
  --context-ui-button-color: var(--secondary-text-color);
  --context-ui-button-hover-color: #fff;
  --context-ui-button-background-color: #fff;
  --context-ui-button-background-hover-color: var(--md-sys-color-primary);

  --context-action-bar-button-margin: var(--margin-narrow) 0 var(--margin-wide) var(--margin-narrow);
  --context-action-bar-more-button-background-color: var(--context-ui-background-color);
  --context-action-bar-more-button-padding: 6px var(--padding-default);
  --context-action-bar-more-button-color: var(--md-sys-color-on-primary);
  --context-action-bar-more-button-border-radius: 4px;
}

@media only screen and (max-width: 460px) {
  body {
    --context-toolbar-button: normal 9px var(--theme-font);
    --context-toolbar-button-max-width: 60px;
    --context-toolbar-button-height: 50px;
    --context-toolbar-iconbutton-size: 30px;
    --context-toolbar-border: none;
    --context-toolbar-border-hover: none;
    --context-toolbar-iconbutton-display: block;
    --context-toolbar-iconbutton-margin: -2px;
    --context-toolbar-button-background-color: transparent;
    --context-toolbar-button-margin: 0;
    --context-toolbar-function-button-height: 34px;
    --context-toolbar-function-button-lineheight: 34px;
    --context-toolbar-function-button-radius: 0;
    --context-toolbar-side-padding: 0;
    --context-toolbar-shadow-line: inset 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
    --context-toolbar-button-padding: 0 10px 0 0;

    --context-action-bar-button-margin: 0;
    --context-action-bar-more-button-border-radius: 0;
    --context-action-bar-more-button-padding: 6px var(--padding-wide);
  }
}
