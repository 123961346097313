:root {
  /* spacing */
  --spacing-none: 0px;
  --spacing-tiny: 2px;
  --spacing-small: 4px;
  --spacing-medium: 8px;
  --spacing-large: 12px;
  --spacing-huge: 24px;

  /* icon size */
  --icon-size-tiny: 14px;
  --icon-size-small: 18px;
  --icon-size-medium: 24px;
  --icon-size-large: 32px;
  --icon-size-huge: 48px;

  /* form element height */
  --form-element-height-tiny: 18px;
  --form-element-height-small: 24px;
  --form-element-height-medium: 30px;
  --form-element-height-large: 40px;
  --form-element-height-huge: 55px;
}
