body {
  --grid-container-border-color: 1px solid rgba(0, 0, 0, 0.09);
  --grid-container-border-width: 1px 0;
  --grid-wrap-container-border: 0px solid transparent;
  --grid-wrap-container-border-width: 0;

  --grist-background-color: transparent;
  --grist-title-margin: 0 0 0 10px;
  --grist-title-border: none;
  --grist-title-font: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500)) 1.1em
    var(--theme-font, 'Roboto');
  --grist-title-color: var(--md-sys-color-on-surface);
  --grist-title-icon-color: var(--md-sys-color-on-surface-variant);
  --grist-title-icon-margin: 0 3px 2px 0;
  --grist-title-icon-size: var(--md-sys-typescale-label-large-size, 0.875rem);
  --grist-title-with-grid-padding: 0;

  --grist-object-editor-font: normal 1em var(--theme-font, 'Roboto');
  --grist-object-editor-color: var(--md-sys-color-on-surface);

  --grist-input-progress-border: 1px solid rgba(255, 255, 255, 0.5);
  --grist-input-progress-background: rgba(121, 110, 110, 0.1);
  --grist-input-progress-bar-background: var(--md-sys-color-primary-fixed-dim);
  --grist-input-progress-bar-color: var(--md-sys-color-on-surface-variant);

  --grid-header-background-color: var(--md-sys-color-surface-container-low);
  --grid-header-top-border: 2px solid var(--md-sys-color-primary);
  --grid-header-bottom-border: var(--grid-container-border-color);
  --grid-header-border-color: var(--md-sys-color-outline);
  --grid-header-padding: var(--spacing-small) var(--spacing-tiny);
  --grid-header-sorter-size: 18px;
  --grid-header-splitter-border: var(--grid-container-border-color);
  --grid-header-splitter-border-hover: 1px solid var(--md-sys-color-inverse-primary);
  --grid-header-color: var(--md-sys-color-primary);
  --grid-header-font: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500))
    var(--fontsize-small) var(--theme-font, 'Roboto');
  --grid-header-filter-title-color: var(--md-sys-color-on-surface);
  --grid-header-filter-title-font: normal 12px var(--theme-font, 'Roboto');
  --grid-header-filter-title-icon-color: var(--md-sys-color-on-surface-variant);

  --grid-body-bottom-border: var(--grid-header-bottom-border);

  --grid-gutter-padding: var(--spacing-tiny) 0;

  --grid-record-background-color: var(--md-sys-color-surface-container-lowest);
  --grid-record-odd-background-color: var(--md-sys-color-surface-container-low);
  --grid-record-padding: var(--spacing-small) var(--spacing-medium);
  --grid-record-color: var(--md-sys-color-on-surface-variant);
  --grid-record-color-hover: var(--md-sys-color-primary);
  --grid-record-wide-fontsize: var(--md-sys-typescale-label-large-size, 0.875rem);
  --grid-record-selected-background-color: var(--md-sys-color-primary-container);
  --grid-record-selected-color: var(--md-sys-color-on-primary-container);
  --grid-record-focused-border: 1px solid var(--md-sys-color-outline-variant);
  --grid-record-focused-cell-background-color: var(--md-sys-color-secondary-container);
  --grid-record-focused-cell-border: 1px dashed var(--md-sys-color-outline);
  --grid-record-focused-box-shadow: 0px 2px 0px 0px rgb(0 0 0 / 10%);
  --grid-record-emphasized-background-color: var(--md-sys-color-secondary-fixed-dim);
  --grid-record-emphasized-color: var(--md-sys-color-on-secondary-fixed-variant);
  --grid-record-editing-background-color: var(--md-sys-color-inverse-primary);
  --grid-record-editing-border: 1px dashed rgba(var(--md-sys-color-primary-rgb), 0.4);
  --grid-record-fontsize: var(--md-sys-typescale-label-large-size, 0.875rem);
  --grid-record-border-bottom: var(--grid-container-border-color);

  --grid-record-dirty-border-top: 24px solid rgba(var(--md-sys-color-primary-rgb), 0.6);
  --grid-record-dirty-border-left: 24px solid transparent;
  --grid-record-dirty-icon-font: bold 10px/12px var(--md-icon-font, 'Material Symbols Outlined');
  --grid-record-dirty-icon-size: var(--fontsize-large);
  --grid-record-dirty-color: var(--md-sys-color-surface);

  --grid-footer-background-color: var(--md-sys-color-surface-container-low);
  --grid-footer-font-size: var(--md-sys-typescale-label-large-size, 0.875rem);
  --grid-footer-color: var(--md-sys-color-primary);
  --grid-footer-limit-color: var(--md-ref-palette-neutral40, #5f5f5f);
  --grid-footer-inactive-color: var(--md-ref-palette-neutral40, #5f5f5f);
  --grid-footer-padding: var(--spacing-small) var(--spacing-medium);

  --data-list-background-color: var(--md-sys-color-surface-container-lowest);
  --data-list-item-margin: var(--spacing-medium);
  --data-list-item-padding: var(--spacing-medium) var(--spacing-large);
  --data-list-item-border-bottom: var(--grid-container-border-color);
  --data-list-item-name-font: bold 1.1em var(--theme-font, 'Roboto');
  --data-list-item-name-color: var(--md-sys-color-secondary);
  --data-list-item-disc-font: normal 0.9em var(--theme-font, 'Roboto');
  --data-list-item-disc-color: var(--md-sys-color-on-tertiary-container);
  --data-list-item-etc-label-font: bold 1em/1em var(--theme-font, 'Roboto');
  --data-list-item-etc-font: normal 0.9em/1em var(--theme-font, 'Roboto');
  --data-list-item-etc-color: var(--md-ref-palette-neutral50, #787878);
  --data-list-item-icon-font: normal 1em/1em;
  --data-list-item-icon-color: var(--md-sys-color-on-secondary);
  --data-list-selected-background-color: var(--grid-record-selected-background-color);
  --data-list-fab-position-horizontal: 15px;
  --data-list-fab-position-vertical: 15px;
  --data-list-fab-color: var(--md-sys-color-primary);
  --data-list-fab-shadow: var(--box-shadow);

  --data-card-background-color: var(--md-sys-color-surface-container);
  --data-card-record-card-background-color: var(--md-sys-color-surface-container-lowest);
  --data-card-record-card-border: var(--grid-container-border-color);
  --data-card-record-card-border-hover: 1px solid var(--md-sys-color-primary);
  --data-card-record-card-boxshadow-hover: 1px 1px 2px 1px rgba(0, 0, 0, 0.15);
  --data-card-record-card-selected-border: 1px solid var(--md-sys-color-primary);
  --data-card-record-card-border-radius: var(--md-sys-shape-corner-small);
  --data-card-item-margin: var(--spacing-medium);
  --data-card-item-padding: var(--spacing-medium) var(--spacing-large);
  --data-card-item-border-bottom: var(--grid-container-border-color);
  --data-card-item-name-font: bold 1.1em var(--theme-font, 'Roboto');
  --data-card-item-name-color: var(--md-sys-color-secondary);
  --data-card-item-name-label-font: normal 0.65em/0.8em var(--theme-font, 'Roboto');
  --data-card-item-name-label-color: var(--md-sys-color-secondary);
  --data-card-item-disc-font: normal 0.9em var(--theme-font, 'Roboto');
  --data-card-item-disc-color: var(--md-sys-color-tertiary);
  --data-card-item-etc-label-font: normal 1em/1em var(--theme-font, 'Roboto');
  --data-card-item-etc-font: normal 0.9em/1em var(--theme-font, 'Roboto');
  --data-card-item-etc-color: var(--md-ref-palette-neutral50, #787878);
  --data-card-item-icon-font: normal 1em/1em;
  --data-card-item-icon-color: var(--md-sys-color-on-secondary);
  --data-card-item-btn-border: var(--grid-container-border-color);
  --data-card-item-btn-border-radius: var(--md-sys-shape-corner-small);
  --data-card-item-btn-padding: var(--spacing-small);
  --data-card-selected-background-color: var(--grid-record-selected-background-color);
  --data-card-fab-position-horizontal: 15px;
  --data-card-fab-position-vertical: 15px;
  --data-card-fab-color: var(--md-sys-color-primary);
  --data-card-fab-shadow: var(--box-shadow);
  --data-card-thumbnail-height: 140px;
  --data-card-thumbnail-border-bottom: var(--grid-container-border-color);
  --data-card-attachimg-height: 70px;

  --record-view-background-color: var(--md-sys-color-surface-container);
  --record-view-gap: var(--spacing-small) 0;
  --record-view-padding: var(--spacing-medium);
  --record-view-label-font: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500)) 15px
    var(--theme-font, 'Roboto');
  --record-view-label-color: var(--md-sys-color-secondary);
  --record-view-label-icon-size: var(--fontsize-small);
  --record-view-font: normal 15px var(--theme-font, 'Roboto');
  --record-view-color: var(--md-sys-color-secondary);
  --record-view-focus-color: var(--md-sys-color-primary);
  --record-view-border-bottom: var(--grid-container-border-color);
  --record-view-edit-border-bottom: 2px solid var(--md-sys-color-primary);
  --record-view-item-padding: var(--spacing-medium);

  --record-view-footer-background: var(--md-ref-palette-neutral50, #787878);
  --record-view-footer-button-border: var(--grid-container-border-color);
  --record-view-footer-button-border-width: 0 0 0 1px;
  --record-view-footer-button-font: 17px;
  --record-view-footer-button-color: var(--md-sys-color-surface);
  --record-view-footer-iconbutton-size: 35px;
  --record-view-footer-focus-background: var(--md-sys-color-primary);

  --ox-grist-padding: var(--spacing-medium);
}

@media only screen and (max-width: 460px) {
  body {
    --record-view-label-font: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500)) 15px/32px
      var(--theme-font, 'Roboto');
    --record-view-font: normal 15px/32px var(--theme-font, 'Roboto');
    --ox-grist-padding: 0;
  }
}
@media (min-width: 461px) and (max-width: 700px) {
  body {
    --ox-grist-padding: 0;
  }
}
@media (min-width: 461px) and (max-width: 1024px) {
  body {
    --data-card-create-form-padding: 7px;
  }
}

body.dark {
  --grid-container-border-color: 1px solid rgba(255, 255, 255, 0.09);
}
