body {
  /* oops note style */
  --oops-note-icon-font: normal 24px var(--md-icon-font, 'Material Symbols Outlined');
  --oops-note-icon-color: var(--md-sys-color-on-surface);
  --oops-note-icon-border: 2px solid var(--md-sys-color-outline);
  --oops-note-icon-border-radius: 50px;
  --oops-note-icon-padding: var(--padding-default);
  --oops-note-title-margin: 7px 0 2px 0;
  --oops-note-title-font: bold 14px var(--theme-font);
  --oops-note-title-color: var(--md-sys-color-secondary);
  --oops-note-description-font: normal 12px var(--theme-font);
  --oops-note-description-color: var(--md-sys-color-primary);
}
@media only screen and (max-width: 460px) {
  body {
    --oops-note-icon-padding: var(--padding-narrow);
    --oops-note-icon-font: normal 18px var(--md-icon-font, 'Material Symbols Outlined');
    --oops-note-title-font: bold 13px var(--theme-font);
    --oops-note-title-margin: var(--margin-narrow) 0 2px 0;
    --oops-note-description-font: normal 0px var(--theme-font);
  }
}
