body {
  /* login page style*/
  --auth-brand-name: bold 20px var(--theme-font);
  --auth-brand-name-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  --auth-brand-welcome-msg: normal 12px var(--theme-font);

  --auth-title-font: bold 32px var(--theme-font);
  --auth-description-font: normal 12px var(--theme-font);
  --auth-description-padding: 5px 5px 15px 5px;

  --auth-input-font: normal 18px/24px var(--theme-font);
  --auth-input-border: 1px solid rgba(0, 0, 0, 0.4);
  --auth-input-border-light: 1px solid rgba(255, 255, 255, 0.8);
  --auth-input-color: var(--md-sys-color-on-primary);

  --auth-button-background-color: var(--md-sys-color-surface-variant);
  --auth-button-background-focus-color: var(--status-success-color);
  --auth-button-font: bold 20px var(--theme-font);
  --auth-button-padding: 9px 12px 7px 12px;

  /* profile page style*/
  --profile-brand-width: 150px;
  --profile-brand-height: 45px;
  --profile-brand-padding: 30px auto 20px auto;

  --profile-wrap-max-width: 550px;
  --profile-icon-margin: 7% 0 0 0;

  --auth-input-field-width: 100%;
  --auth-input-field-font: normal 16px var(--theme-font);

  --change-password-field-font: var(--auth-input-field-font);
  --change-password-field-border: 1px solid rgba(0, 0, 0, 0.2);
  --change-password-field-border-radius: var(--border-radius);
  --change-password-field-margin: var(--margin-narrow) 0;
  --change-password-field-padding: var(--padding-default);
  --change-password-field-width: var(--auth-input-field-width);

  --auth-special-page-padding: 250px 0 100px 0;

  /* domain select page style*/
  --checkin-background-color: var(--md-sys-color-background);
  --checkin-header-height: 45px;
  --checkin-header-background-color: var(--md-sys-color-primary);
  --checkin-header-title-font: bold 19px var(--theme-font);
  --checkin-header-title-color: var(--md-sys-color-on-primary);
  --checkin-wrap-max-width: 500px;
  --checkin-wrap-padding: var(--padding-wide);
}

@media only screen and (max-width: 460px) {
  body {
    /* profile page style*/
    --profile-wrap-max-width: 85vw;

    --auth-input-field-font: normal 20px var(--theme-font);
  }
}

@media (min-width: 461px) and (max-width: 1024px) {
  body {
    /* profile page style*/
    --auth-input-field-font: normal 20px var(--theme-font);
  }
}
