body {
  --help-panel-background-color: var(--md-sys-color-surface-variant);
  --help-panel-width: 450px;
  --help-panel-margin: 0;
  --help-panel-padding: var(--padding-default) 0 var(--padding-default) var(--padding-wide);
  --help-panel-border-raidus: 9px;
  --help-panel-box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.15);
  --help-panel-border: 2px solid var(--md-sys-color-primary);

  --help-panel-color: var(--md-sys-color-on-surface-variant);

  --help-panel-title-margin: var(--margin-default) 0 var(--margin-narrow) 0;
  --help-panel-title-padding: 0;
  --help-panel-title-background-color: var(--md-sys-color-surface);
  --help-panel-h1-title-font-size: 1.6em;
  --help-panel-h1-title-color: var(--md-sys-color-primary);
  --help-panel-h2-title-font-size: 1.3em;
  --help-panel-h2-title-color: var(--md-sys-color-secondary);
  --help-panel-h3-title-font-size: 1.1em;
  --help-panel-h3-title-color: var(--secondary-text-color);

  --help-panel-content-padding: var(--padding-default) var(--padding-wide);
  --help-panel-paragraph-margin: 0 0 var(--margin-default) 0;
  --help-panel-font-size: 0.9em;
  --help-panel-content-font-size: 0.8em;

  --help-panel-focusBox-padding: var(--padding-narrow) var(--padding-default);
  --help-panel-focusBox-border: 1px solid rgba(0, 0, 0, 0.2);
  --help-panel-focusBox-border-radius: var(--border-radius);
  --help-panel-focusBox-background-color: rgba(0, 0, 0, 0.2);
  --help-panel-focusBox-color: #ffe393;

  --help-panel-a-color: var(--md-sys-color-secondary);
  --help-panel-a-icon-size: 1.3em;

  --help-icon-color: var(--md-sys-color-secondary);
  --help-icon-hover-color: var(--md-sys-color-primary);
  --help-icon-size: 18px;
  --help-icon-opacity: 0.4;
  --help-icon-hover-opacity: 1;

  --help-navigation-padding: 0;
  --help-navigation-bottom: var(--md-sys-color-surface-variant);
  --help-navigation-icon-padding: 10px;
  --help-navigation-icon-border: 1px solid rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 460px) {
  body {
    --help-panel-width: 100vw;
    --help-panel-border-raidus: 0;
    --help-panel-border: none;
    --help-panel-box-shadow: none;

    --help-panel-title-padding: 0 0 0 var(--padding-wide);
  }
}
