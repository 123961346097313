.light {
  --md-sys-color-primary: rgb(77 92 146);
  --md-sys-color-surface-tint: rgb(77 92 146);
  --md-sys-color-on-primary: rgb(255 255 255);
  --md-sys-color-primary-container: rgb(220 225 255);
  --md-sys-color-on-primary-container: rgb(4 22 75);
  --md-sys-color-secondary: rgb(77 92 146);
  --md-sys-color-on-secondary: rgb(255 255 255);
  --md-sys-color-secondary-container: rgb(220 225 255);
  --md-sys-color-on-secondary-container: rgb(3 23 75);
  --md-sys-color-tertiary: rgb(117 84 111);
  --md-sys-color-on-tertiary: rgb(255 255 255);
  --md-sys-color-tertiary-container: rgb(255 215 245);
  --md-sys-color-on-tertiary-container: rgb(44 18 41);
  --md-sys-color-error: rgb(186 26 26);
  --md-sys-color-on-error: rgb(255 255 255);
  --md-sys-color-error-container: rgb(255 218 214);
  --md-sys-color-on-error-container: rgb(65 0 2);
  --md-sys-color-background: rgb(250 248 255);
  --md-sys-color-on-background: rgb(26 27 33);
  --md-sys-color-surface: rgb(250 248 255);
  --md-sys-color-on-surface: rgb(26 27 33);
  --md-sys-color-surface-variant: rgb(226 225 236);
  --md-sys-color-on-surface-variant: rgb(69 70 79);
  --md-sys-color-outline: rgb(118 118 128);
  --md-sys-color-outline-variant: rgb(198 197 208);
  --md-sys-color-shadow: rgb(0 0 0);
  --md-sys-color-scrim: rgb(0 0 0);
  --md-sys-color-inverse-surface: rgb(47 48 54);
  --md-sys-color-inverse-on-surface: rgb(241 240 247);
  --md-sys-color-inverse-primary: rgb(182 196 255);
  --md-sys-color-primary-fixed: rgb(220 225 255);
  --md-sys-color-on-primary-fixed: rgb(4 22 75);
  --md-sys-color-primary-fixed-dim: rgb(182 196 255);
  --md-sys-color-on-primary-fixed-variant: rgb(53 68 121);
  --md-sys-color-secondary-fixed: rgb(220 225 255);
  --md-sys-color-on-secondary-fixed: rgb(3 23 75);
  --md-sys-color-secondary-fixed-dim: rgb(182 196 255);
  --md-sys-color-on-secondary-fixed-variant: rgb(53 68 121);
  --md-sys-color-tertiary-fixed: rgb(255 215 245);
  --md-sys-color-on-tertiary-fixed: rgb(44 18 41);
  --md-sys-color-tertiary-fixed-dim: rgb(227 186 218);
  --md-sys-color-on-tertiary-fixed-variant: rgb(91 61 87);
  --md-sys-color-surface-dim: rgb(218 217 224);
  --md-sys-color-surface-bright: rgb(250 248 255);
  --md-sys-color-surface-container-lowest: rgb(255 255 255);
  --md-sys-color-surface-container-low: rgb(244 243 250);
  --md-sys-color-surface-container: rgb(239 237 244);
  --md-sys-color-surface-container-high: rgb(233 231 239);
  --md-sys-color-surface-container-highest: rgb(227 225 233);
}
