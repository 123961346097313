body {
  /* theme color */
  --primary-color: var(--md-sys-color-primary);
  --secondary-color: var(--md-sys-color-secondary);
  --focus-color: var(--md-sys-color-on-primary);
  --primary-background-color: var(--md-sys-color-primary-container);
  --secondary-background-color: var(--md-sys-color-secondary-container);

  --focus-background-color: var(--md-sys-color-primary);
  --primary-text-color: var(--md-sys-color-on-primary);
  --secondary-text-color: var(--md-sys-color-on-secondary);

  /* common style */
  --border-radius: 4px;
  --border-dim-color: 1px solid rgba(0, 0, 0, 0.15);
  --border-light-color: 1px solid rgba(255, 255, 255, 0.3);

  --box-shadow: 2px 2px 3px 0px rgba(0, 0, 0, 0.1);

  --theme-font: 'Noto', Helvetica;

  --margin-default: var(--spacing-medium, 8px);
  --margin-narrow: var(--spacing-small, 4px);
  --margin-wide: var(--spacing-large, 12px);
  --padding-default: var(--spacing-medium, 8px);
  --padding-narrow: var(--spacing-small, 4px);
  --padding-wide: var(--spacing-large, 12px);

  --scrollbar-thumb-color: rgba(57, 78, 100, 0.5);
  --scrollbar-thumb-hover-color: var(--md-sys-color-primary);

  --fontsize-default: 14px;
  --fontsize-small: 13px;
  --fontsize-large: 16px;

  /* app layout style */
  --app-grid-template-area: 'header header header' 'nav main aside' 'nav footer aside';

  /* title & description style */
  --title-margin: var(--margin-narrow) 0;
  --title-font: bold 24px var(--theme-font);
  --title-text-color: var(--md-sys-color-secondary);
  --title-font-mobile: bold 20px var(--theme-font);

  --page-description-margin: var(--margin-narrow) 0 var(--margin-wide) 0;
  --page-description-font: normal var(--fontsize-default) / 1.2rem var(--theme-font);
  --page-description-color: var(--md-sys-color-primary);

  --subtitle-padding: 12px 5px 3px 5px;
  --subtitle-font: bold 18px var(--theme-font);
  --subtitle-text-color: var(--md-sys-color-primary);
  --subtitle-border-bottom: 1px solid var(--md-sys-color-primary);

  /* icon style */
  --icon-tiny-size: var(--icon-size-medium, 24px);
  --icon-default-size: var(--icon-size-large, 32px);
  --icon-big-size: var(--icon-size-huge, 48px);
  --icon-default-color: var(--md-sys-color-on-primary);

  /* material design component themes */
  --mdc-theme-on-primary: var(--md-sys-color-on-primary);
  --mdc-theme-primary: var(--secondary-text-color);
  --mdc-theme-on-secondary: var(--md-sys-color-on-primary);
  --mdc-theme-secondary: var(--md-sys-color-primary);
  --mdc-button-outline-color: var(--md-sys-color-primary);
  --mdc-danger-button-primary-color: var(--status-danger-color);
  --mdc-danger-button-outline-color: var(--status-danger-color);
  --mdc-button-outline-width: 1px;
  --mdc-button-horizontal-padding: 16px;

  /* button style */
  --button-background-color: var(--md-sys-color-surface-variant);
  --button-background-focus-color: var(--md-sys-color-primary);
  --button-border: var(--border-dim-color);
  --button-border-radius: var(--border-radius);
  --button-margin: var(--margin-default) var(--margin-default) var(--margin-default) 0;
  --button-padding: calc(var(--padding-narrow) * 1.5) var(--padding-wide);
  --button-color: var(--md-sys-color-secondary);
  --button-font: 600 var(--fontsize-default) var(--theme-font);
  --button-text-transform: capitalize;
  --button-active-box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.2);
  --button-activ-border: 1px solid var(--md-sys-color-primary);
  --button-activ-background-color: var(--md-sys-color-primary);
  --button-activ-color: var(--md-sys-color-on-primary);
  --iconbtn-padding: var(--padding-narrow);

  --button-primary-background-color: var(--md-sys-color-primary);
  --button-primary-active-background-color: var(--status-success-color);
  --button-primary-padding: var(--margin-default) var(--margin-wide);
  --button-primary-color: var(--md-sys-color-on-primary);
  --button-primary-font: bold 16px var(--theme-font);

  /* table style */
  --th-padding: var(--padding-default);
  --th-border-top: 2px solid var(--md-sys-color-secondary);
  --th-text-transform: capitalize;
  --th-font: bold var(--fontsize-small) var(--theme-font);
  --th-color: var(--md-sys-color-secondary);

  --tr-background-color: var(--md-sys-color-surface-variant);
  --tr-background-odd-color: rgba(255, 255, 255, 0.4);
  --tr-background-hover-color: #e1f5fe;
  --td-border-line: 1px solid rgba(0, 0, 0, 0.05);
  --td-border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  --td-padding: var(--padding-default);
  --td-font: normal 13px var(--theme-font);
  --td-color: var(--md-sys-color-secondary);

  --label-cell-background-color: var(--md-sys-color-surface); /* th or td common background color */

  /* form style */
  --label-font: normal var(--fontsize-default) var(--theme-font);
  --label-color: var(--md-sys-color-on-surface);
  --label-text-transform: capitalize;
  --input-margin: var(--margin-narrow) 0;
  --input-padding: 6px 2px;
  --input-min-width: 200px;
  --input-font: normal var(--fontsize-default) var(--theme-font);
  --input-hint-font: normal var(--fontsize-small) var(--theme-font);
  --input-hint-color: #666;
  --input-container-max-width: 900px;
  --fieldset-margin: var(--padding-wide) 0;
  --fieldset-padding: 0 var(--padding-wide) var(--padding-wide) var(--padding-wide);
  --legend-padding: var(--padding-default) 0;
  --legend-color: var(--md-sys-color-secondary);
  --legend-font: bold 16px var(--theme-font);
}

body.dark {
  caret-color: white;
}

@media only screen and (max-width: 460px) {
  body {
    /* subtitle style */
    --subtitle-margin: 0;
  }
}
