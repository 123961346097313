body {
  margin: 0;
  padding: 0;
  overflow: hidden;

  overscroll-behavior-y: none;

  /* This is a font-stack that tries to use the system-default sans-serifs first */
  font-family: Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;

  accent-color: var(--md-sys-color-primary);
  background-color: var(--md-sys-color-background);
}
