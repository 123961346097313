body {
  /* system info style */
  --system-info-background-color: var(--md-sys-color-background);
  --system-info-content-padding: 40px 0 0 0;
  --system-info-font: normal 14px/24px var(--theme-font);
  --system-info-color: #666;
  --system-info-appname-color: var(--md-sys-color-secondary);
  --system-info-appname-font: bold 20px/20px var(--theme-font);
  --system-info-description-color: var(--md-sys-color-primary);
  --system-info-description-font: normal 15px/18px var(--theme-font);
  --system-info-version-background-color: var(--md-sys-color-secondary);
  --system-info-version-color: #fff;
  --system-info-version-font: normal 15px/15px var(--theme-font);
}
