@layer {
  .md-typescale-display-small,
  .md-typescale-display-small-prominent {
    font: var(--md-sys-typescale-display-small-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-display-small-size, 2.25rem) / var(--md-sys-typescale-display-small-line-height, 2.75rem)
      var(--md-sys-typescale-display-small-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-display-medium,
  .md-typescale-display-medium-prominent {
    font: var(--md-sys-typescale-display-medium-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-display-medium-size, 2.8125rem) /
      var(--md-sys-typescale-display-medium-line-height, 3.25rem)
      var(--md-sys-typescale-display-medium-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-display-large,
  .md-typescale-display-large-prominent {
    font: var(--md-sys-typescale-display-large-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-display-large-size, 3.5625rem) / var(--md-sys-typescale-display-large-line-height, 4rem)
      var(--md-sys-typescale-display-large-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-headline-small,
  .md-typescale-headline-small-prominent {
    font: var(--md-sys-typescale-headline-small-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-headline-small-size, 1.5rem) / var(--md-sys-typescale-headline-small-line-height, 2rem)
      var(--md-sys-typescale-headline-small-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-headline-medium,
  .md-typescale-headline-medium-prominent {
    font: var(--md-sys-typescale-headline-medium-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-headline-medium-size, 1.75rem) /
      var(--md-sys-typescale-headline-medium-line-height, 2.25rem)
      var(--md-sys-typescale-headline-medium-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-headline-large,
  .md-typescale-headline-large-prominent {
    font: var(--md-sys-typescale-headline-large-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-headline-large-size, 2rem) / var(--md-sys-typescale-headline-large-line-height, 2.5rem)
      var(--md-sys-typescale-headline-large-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-title-small,
  .md-typescale-title-small-prominent {
    font: var(--md-sys-typescale-title-small-weight, var(--md-ref-typeface-weight-medium, 500))
      var(--md-sys-typescale-title-small-size, 0.875rem) / var(--md-sys-typescale-title-small-line-height, 1.25rem)
      var(--md-sys-typescale-title-small-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-title-medium,
  .md-typescale-title-medium-prominent {
    font: var(--md-sys-typescale-title-medium-weight, var(--md-ref-typeface-weight-medium, 500))
      var(--md-sys-typescale-title-medium-size, 1rem) / var(--md-sys-typescale-title-medium-line-height, 1.5rem)
      var(--md-sys-typescale-title-medium-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-title-large,
  .md-typescale-title-large-prominent {
    font: var(--md-sys-typescale-title-large-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-title-large-size, 1.375rem) / var(--md-sys-typescale-title-large-line-height, 1.75rem)
      var(--md-sys-typescale-title-large-font, var(--md-ref-typeface-brand, Roboto));
  }
  .md-typescale-body-small,
  .md-typescale-body-small-prominent {
    font: var(--md-sys-typescale-body-small-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-body-small-size, 0.75rem) / var(--md-sys-typescale-body-small-line-height, 1rem)
      var(--md-sys-typescale-body-small-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-body-medium,
  .md-typescale-body-medium-prominent {
    font: var(--md-sys-typescale-body-medium-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-body-medium-size, 0.875rem) / var(--md-sys-typescale-body-medium-line-height, 1.25rem)
      var(--md-sys-typescale-body-medium-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-body-large,
  .md-typescale-body-large-prominent {
    font: var(--md-sys-typescale-body-large-weight, var(--md-ref-typeface-weight-regular, 400))
      var(--md-sys-typescale-body-large-size, 1rem) / var(--md-sys-typescale-body-large-line-height, 1.5rem)
      var(--md-sys-typescale-body-large-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-label-small,
  .md-typescale-label-small-prominent {
    font: var(--md-sys-typescale-label-small-weight, var(--md-ref-typeface-weight-medium, 500))
      var(--md-sys-typescale-label-small-size, 0.6875rem) / var(--md-sys-typescale-label-small-line-height, 1rem)
      var(--md-sys-typescale-label-small-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-label-medium,
  .md-typescale-label-medium-prominent {
    font: var(--md-sys-typescale-label-medium-weight, var(--md-ref-typeface-weight-medium, 500))
      var(--md-sys-typescale-label-medium-size, 0.75rem) / var(--md-sys-typescale-label-medium-line-height, 1rem)
      var(--md-sys-typescale-label-medium-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-label-medium-prominent {
    font-weight: var(--md-sys-typescale-label-medium-weight-prominent, var(--md-ref-typeface-weight-bold, 700));
  }
  .md-typescale-label-large,
  .md-typescale-label-large-prominent {
    font: var(--md-sys-typescale-label-large-weight, var(--md-ref-typeface-weight-medium, 500))
      var(--md-sys-typescale-label-large-size, 0.875rem) / var(--md-sys-typescale-label-large-line-height, 1.25rem)
      var(--md-sys-typescale-label-large-font, var(--md-ref-typeface-plain, Roboto));
  }
  .md-typescale-label-large-prominent {
    font-weight: var(--md-sys-typescale-label-large-weight-prominent, var(--md-ref-typeface-weight-bold, 700));
  }
} /*# sourceMappingURL=md-typescale-styles.css.map */
