body {
  --more-panel-background-color: var(--md-sys-color-secondary-fixed-dim);
  --more-panel-head-color: var(--more-panel-background-color);
  --more-panel-tail-color: var(--more-panel-background-color);
  --more-panel-min-width: 200px;

  --morelet-min-width: 200px;
  --morelet-title-color: var(--md-sys-color-on-secondary-fixed);
  --morelet-title-font: normal 14px var(--theme-font);
  --morelet-border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  --morelet-focus-border-top: 3px solid var(--md-sys-color-primary);
  --morelet-background-color: var(--md-sys-color-surface);
  --morelet-padding: var(--padding-default) var(--padding-narrow) var(--padding-narrow) var(--padding-default);
  --morelet-color: var(--md-sys-color-on-secondary-fixed);
  --morelet-font: normal 0.9em/1.4em var(--theme-font);
  --morelet-icon-color: var(--md-sys-color-on-primary);
}

@media screen and (max-width: 460px) {
  body {
    --morelet-padding: var(--padding-wide);
    --morelet-font: normal 1em/0.9em var(--theme-font);
  }
}
