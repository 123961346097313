body {
  font-variation-settings: 'FILL' 1;

  /* shape corner */
  --md-sys-shape-corner-none: 0px;
  --md-sys-shape-corner-small: 5px;
  --md-sys-shape-corner-medium: 10px;
  --md-sys-shape-corner-large: 30px;
  --md-sys-shape-corner-full: 999px;

  /* md-switch */
  --md-switch-selected-handle-color: var(--md-sys-color-on-primary);
  --md-switch-selected-track-color: var(--md-sys-color-primary);

  /* md component shapes */
  --md-switch-handle-shape: var(--md-sys-shape-corner-full);
  --md-switch-track-shape: var(--md-sys-shape-corner-full);
  --md-filter-chip-container-shape: var(--md-sys-shape-corner-full);
  --md-text-button-container-shape: var(--md-sys-shape-corner-small);
  --md-filled-button-container-shape: var(--md-sys-shape-corner-small);
  --md-outlined-button-container-shape: var(--md-sys-shape-corner-small);
  --md-elevated-button-container-shape: var(--md-sys-shape-corner-small);
}
