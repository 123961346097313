body {
  --ox-calendar-padding: var(--padding-default);

  /* monthly common color */
  --calendar-monthly-text-color: var(--md-sys-color-secondary);
  --calendar-monthly-border: 1px solid rgba(0, 0, 0, 0.1);
  --calendar-monthly-border-width: 0 1px 1px 0px;

  /* monthly layout */
  --calendar-monthly-ol-margin: var(--margin-default) 0;
  --calendar-monthly-ol-top-border: 2px solid var(--md-sys-color-secondary);
  --calendar-current-monty-background-color: var(--md-sys-color-surface-variant);
  --calendar-monthly-label-align: left;
  --calendar-monthly-label-padding: var(--padding-narrow) 0;
  --calendar-monthly-label-color: var(--md-sys-color-secondary);
  --calendar-monthly-opacity: 0.5;
  --calendar-monthly-date-label-background-color: var(--status-danger-color);
  --calendar-monthly-date-label-margin: var(--margin-narrow);
  --calendar-monthly-date-label-padding: 0px 6px;
  --calendar-monthly-date-label-color: var(--md-sys-color-on-primary);

  --calendar-monthly-background-color: #f4f4f4;
  --calendar-monthly-event-border-radius: var(--border-radius);
  --calendar-monthly-event-margin: var(--padding-narrow) 0 0 0;
  --calendar-monthly-event-padding: 2px var(--padding-default);
  --calendar-monthly-event-border: 3px solid #ccc;
  --calendar-monthly-event-border-width: 0 0 0 3px;

  /* weekly common color */
  --calendar-weekly-text-color: var(--md-sys-color-secondary);
  --calendar-weekly-border: 1px solid rgba(0, 0, 0, 0.1);
  --calendar-weekly-border-width: 0 1px 1px 0px;

  /* weekly layout */
  --calendar-weekly-ol-margin: var(--margin-default) 0;
  --calendar-weekly-ol-top-border: 2px solid var(--md-sys-color-secondary);
  --calendar-current-week-background-color: var(--md-sys-color-surface-variant);
  --calendar-weekly-label-align: center;
  --calendar-weekly-label-padding: var(--padding-narrow) 0;
  --calendar-weekly-label-color: var(--md-sys-color-secondary);
  --calendar-weekly-opacity: 0.5;
  --calendar-weekly-date-label-background-color: var(--status-danger-color);
  --calendar-weekly-date-label-margin: var(--margin-narrow);
  --calendar-weekly-date-label-padding: 0px 6px;
  --calendar-weekly-date-label-color: var(--md-sys-color-on-primary);

  --calendar-weekly-background-color: #f4f4f4;
  --calendar-weekly-event-border-radius: var(--border-radius);
  --calendar-weekly-event-margin: var(--padding-narrow) 0 0 0;
  --calendar-weekly-event-padding: 2px var(--padding-default);
  --calendar-weekly-event-border: 3px solid #ccc;
  --calendar-weekly-event-border-width: 0 0 0 3px;
}

@media only screen and (max-width: 460px) {
  body {
    --calendar-monthly-text-color: tomato;
  }
}
